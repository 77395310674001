const prefix = process.env.NODE_ENV === 'development' ? 'document-manager' : 'document-manager';

const baseUrl =
    process.env.NODE_ENV === 'development' ? 'https://api.staging.gapstack.com' : process.env.REACT_APP_API_BASE_URL;

export const upload_url = `${baseUrl}/${prefix}/storage`;
export const upload_url_without_base = `${prefix}/storage`;
export const verify_live_image =
    'https://gapstackdocsweb.azurewebsites.net/api/WebServiceAPI?code=ur7cUr/RkpQbRh1UUZEfNHaymTCUzi70unktDbZnY0vNfgbkEtAZMg==';
export const get_file_information = (fileId, pageType) =>
    `${prefix}/storage/document/extract-information/${fileId}?pageType=${pageType}`;
export const document_manager_url = (url: any) => `${baseUrl}/${prefix}${url}`;
